// Dependencies: pnpm install lucide-react
'use client';

import React, { useState } from 'react';

import { cn } from '@/lib/cn';
import { EyeIcon, EyeOffIcon } from 'lucide-react';

import { FormDescription, FormError, Label } from '../form';

// Dependencies: pnpm install lucide-react

export interface PasswordInputProps extends React.ComponentProps<'input'> {
  name?: string;
  error?: string;
  label?: string;
  description?: string;
  defaultVisible?: boolean;
  classNames?: {
    container?: string;
    label?: string;
    input?: string;
    description?: string;
    message?: string;
  };
}

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ className, classNames, description, error, label, ...props }, ref) => {
    const [isVisible, setIsVisible] = useState<boolean>(
      props.defaultVisible || false,
    );

    const toggleVisibility = () => setIsVisible((prevState) => !prevState);

    const htmlFor = props.id || props.name;

    return (
      <div className={cn('space-y-1', classNames?.container)}>
        <Label htmlFor={htmlFor} className={classNames?.label}>
          {label}
        </Label>
        <div className='relative'>
          <input
            ref={ref}
            className={cn(
              'flex h-10 w-full rounded-lg border border-input px-3 py-2 text-sm text-[#30313D] ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
              {
                'border-destructive focus-visible:ring-destructive': error,
              },
              classNames?.input,
              className,
            )}
            {...props}
            type={isVisible ? 'text' : 'password'}
          />
          <button
            className='absolute inset-y-0 end-0 flex h-full w-9 items-center justify-center rounded-e-lg text-muted-foreground/80 outline-offset-2 transition-colors hover:text-custom-gray-500 focus:z-10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-ring/70 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50'
            type='button'
            onClick={toggleVisibility}
            aria-label={isVisible ? 'Hide password' : 'Show password'}
            aria-pressed={isVisible}
            aria-controls='password'
          >
            {isVisible ? (
              <EyeOffIcon size={16} strokeWidth={2} aria-hidden='true' />
            ) : (
              <EyeIcon size={16} strokeWidth={2} aria-hidden='true' />
            )}
          </button>
        </div>

        <FormDescription className={classNames?.description}>
          {description}
        </FormDescription>
        <FormError className={classNames?.message}>{error}</FormError>
      </div>
    );
  },
);

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
