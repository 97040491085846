import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const LoginValidations = z.object({
  user: z.string().min(1, 'login.errors.user.required'),
  password: z.string().min(1, 'login.errors.password.required'),
});

export type LoginFormValues = z.infer<typeof LoginValidations>;

export const LoginFormResolver = zodResolver(LoginValidations);
