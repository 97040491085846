'use client';

import { useState } from 'react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';

import { useScopedI18n } from '@/lib/i18n/client';
import {
  LoginFormResolver,
  LoginFormValues,
} from '@/validations/login/login-form-validation';
import { signIn } from 'next-auth/react';
import { toast } from 'sonner';

import { LoginFormProps } from '.';

export const useUserLogin = ({ callback }: LoginFormProps) => {
  const t = useScopedI18n('login');

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<LoginFormValues>({
    resolver: LoginFormResolver,
    defaultValues: {
      user: '',
      password: '',
    },
  });

  // const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (data: LoginFormValues) => {
    setIsLoading(true);
    const toastId = toast.loading(t('loading'));

    // if (!executeRecaptcha) {
    //   setIsLoading(false);
    //   toast.error('reCaptcha error', {
    //     id: toastId,
    //   });
    //   return;
    // }

    // const token = await executeRecaptcha('recover_password');

    // const recaptchaResponse = await recaptchaVerify(token);

    // if (!recaptchaResponse.success) {
    //   setIsLoading(false);
    //   toast.error('reCaptcha error', {
    //     id: toastId,
    //   });
    //   return;
    // }

    const response = await signIn('credentials', {
      email: data.user,
      password: data.password,
      redirect: false,
      callbackUrl: callback || '/',
    });

    if (response?.error) {
      toast.error(t('error'), {
        id: toastId,
      });
      setIsLoading(false);
      return;
    }

    toast.success(t('success'), { id: toastId });

    setIsLoading(false);

    // @ts-ignore
    window.location.href = response.url;
  };

  return {
    form,
    isLoading,
    onSubmit,
  };
};
