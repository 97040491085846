'use client';

import { useScopedI18n } from '@/lib/i18n/client';

import { useUserLogin } from '@/containers/login/use-login-form';

import { Form } from '@/components/form';
import { FormInputWrapper } from '@/components/form/form-wrapper';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import PasswordInput from '@/components/ui/password-input';

export interface LoginFormProps {
  callback?: string;
}

const UserLoginForm = ({ callback }: LoginFormProps) => {
  const t = useScopedI18n('login');
  const { isLoading, form, onSubmit } = useUserLogin({ callback });

  return (
    <Form form={form} onSubmit={onSubmit} className='w-full space-y-5'>
      <FormInputWrapper
        input={Input}
        name='user'
        type='email'
        placeholder={t('entry.user')}
      />

      {/* <FormInputWrapper
        input={Input}
        name='password'
        type='password'
        placeholder={t('entry.password')}
      /> */}

      <FormInputWrapper
        input={PasswordInput}
        name='password'
        placeholder={t('entry.password')}
      />

      <Button type='submit' loading={isLoading} className='w-full'>
        {t('buttom.login')}
      </Button>
    </Form>
  );
};

export default UserLoginForm;
